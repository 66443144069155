<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :statusOptions="status"
      > 
        <template #filters>
          <b-col md=2>
            <VSelect 
            v-model="filter.level" 
            placeholder="-- Pilih Level --"
            :options="mrBranchLevel" 
            :reduce="v=>String(v.value)" 
            @input="doFilter"
            />
          </b-col>
          <b-col md=2>
            <VSelect 
            v-model="filter.unitKerja" 
            placeholder="-- Pilih Unit Kerja --"
            :options="mrUnitKerja" 
            :reduce="v=>String(v.value)" 
            @input="doFilter"
            />
          </b-col>
          <b-col md=2>
            <VSelect 
              v-model="filter.perspektif" 
              placeholder="-- Pilih Perspektif --"
              :options="mrPerspektif" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=2>
            <VSelect 
              v-model="filter.status" 
              placeholder="-- Semua Status --"
              :options="status" 
              :reduce="v=>v.value" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #msr_status="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #mbl_name="data">
          {{ data.scope.value.charAt(0).toUpperCase() + data.scope.value.slice(1) }}
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item @click="doDelete(data.scope.index, data.scope.item)">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                v-for="(s,k) in status"
                :class="{markedDropdown:data.scope.item[statusKey]==s.value}"
                @click="doChangeStatus(s, data.scope.item[idKey])"
                :key="k"
              >
                {{s.label}}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row class="mb-3">
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Unit Kerja Level<span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.muk_level" 
                    placeholder="-- Pilih Satu --"
                    :options="mrBranchLevel" 
                    @input="doChangeUnitKerjaLevel"
                    :reduce="v=>v.value"
                    :disabled="!isAdd"
                  />
                  <VValidate 
                    name="Unit Kerja Level" 
                    v-model="row.muk_level" 
                    :rules="{required: 1}" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6 v-if="row.muk_level||!isAdd">
                <b-form-group>
                  <template #label>
                    Unit Kerja <span class="text-danger mr5">*</span>
                  </template>
                  <!--multiple class="vs__multiple"-->
                  <!--:reduce="(v) => String(v.value)"-->
                  <VSelect 
                    v-model="row.msr_unit_kerja" 
                    placeholder="-- Pilih Satu --"
                    :disabled="!isAdd"
                    :options="mrUnitKerja" 
                    :reduce="(v) => v.value"
                  />
                  <!-- <VValidate 
                    name="Unit Kerja" 
                    :value="(row.msr_unit_kerja||[]).length?1:''" 
                    :rules="mrValidation.msr_unit_kerja" 
                  /> -->
                  <VValidate 
                    name="Unit Kerja" 
                    v-model="row.msr_unit_kerja" 
                    :rules="mrValidation.msr_unit_kerja" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
             <b-col md=6>
                <b-form-group>
                  <template #label>
                    Perspektif <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.msr_perspektif_id" 
                    placeholder="-- Pilih Satu --"
                    :disabled="!isAdd"
                    :options="mrPerspektif"
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Perspektif" 
                    v-model="row.msr_perspektif_id" 
                    :rules="mrValidation.msr_perspektif_id" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3"> 
                <b-col md=12>
                    <b-form-group>
                        <template #label>
                            Sasaran RTU <span class="text-danger mr5">*</span>
                        </template>
                        <b-form-textarea
                            id="textarea"
                            v-model="row.msr_description"
                            placeholder="Tulis Sasaran RTU..."
                            rows="3"
                            no-resize
                            ></b-form-textarea>

                        <VValidate 
                            name="Sasaran RTU" 
                            v-model="row.msr_description" 
                            :rules="mrValidation.msr_description" 
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
               <b-col
                md="6"
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen'

let _ = global._

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'msr_id',
      statusKey:'msr_status',
      fields:[
        { key: "select", label: "" },
        { key:'number', label:'#' },
        { key:'msr_code', label:'ID Sasaran RTU' },
        { key:'msr_description', label:'Sasaran RTU' },
        { key:'mp_description', label:'Perspektif' },
        //{ key:'unit_kerja_name', label:'Unit Kerja' },
        { key:'muk_description', label:'Unit Kerja' },
        { key:'mbl_name', label:'Unit Kerja Level', is_custom: true },
        { key:'msr_status', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true, tdClass: "width_170", thClass: "width_170" },
      ],
      mrBranchLevel:[],
      mrPerspektif: [],
      mrUnitKerja: [],
      mrUnitKerjaTemp: [],
      status: []
    }
  },
  mounted(){
    this.apiGet()
    
  },

  methods: {
    doChangeStatus(status, id){
      global.Swal.fire({
        title: `Ingin mengubah status data menjadi ${status.label}?`,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#0097D8',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ${status.label}!`,
        cancelButtonText: 'Batal',
        customClass: {
          // confirmButton: 
          // `btn btn-primary ${
          //   status.value =='approved' ? 'btn-success' : status.value=='draft' ? 'btn-warning' :
          //   status.value =='purpose' ? 'btn-info' : status.value == 'rejected' ? 'btn-danger' : status.value=='obsolete' ? 'btn-dark' : 'btn-danger'
          // }`,
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(res => {
          if(res.value){
            this.loadingOverlay = true

            Gen.apiRest(
              "/do/"+this.modulePage,
              {data: {type: "status", id: id, status: status.value}},
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                global.Swal.fire({
                  title: `Status data berubah menjadi ${status.label}.`,
                  icon: 'success',
                })
                this.apiGet()

              })
              .catch(()=>{ 
                this.loadingOverlay = false
              })
          }
        })
    },
    doChangeUnitKerjaLevel() {
      let FilterUnitLevel = _.filter(this.mrUnitKerjaTemp, (v)=>{
        return v.level==this.row.muk_level;
      })
      // this.input.filter_unit_kerja = ""
      this.$set(this,'mrUnitKerja',FilterUnitLevel)
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'row.muk_level'(v,o){
      if(o && v && this.isAdd){
        this.$set(this.row, 'msr_unit_kerja', null)
      }
    }
  }
}
</script>